.Toggle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    background-color: #eee;
    padding: 10px;
    width: 300px;


    &__subToggles {
        display: none;
        background-color: #eee;
        padding: 0px 10px;

        &--visible {
            display: block;
        }

        & > .Toggle {
            width: 280px;
        }
    }
}